import * as React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

import {
  calculatePerformanceLevel,
  calculateScore,
  calculateScoreAfterMitigation,
} from "./utils";
import { Risk as RiskInterface } from "./types";
import { Feature, useFeatureFlags } from "context/FeatureFlag";
import { useDeleteRisk } from "./hooks/useUpdateRisk";
import { useConfirmation } from "context/Confirmation";
import Collapsible from "components/Collapsible/Collapsible";
import { DeleteButton } from "components/Button/DeleteButton";
import { PrimaryButton } from "components/Button/PrimaryButton";

type RiskProps = { risk: RiskInterface; projectId: string; idx: number };

export const Risk: React.FunctionComponent<RiskProps> = (props) => {
  const { risk, projectId } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { openConfirmation } = useConfirmation();
  const totalRisk = calculateScore(risk.assessment);
  const deleteRisk = useDeleteRisk(projectId, risk.resource_id);

  const handleDelete = () => {
    openConfirmation(deleteRisk, {
      label: t("form.basic.label"),
      subline: t("steps.risks.confirmation.subline"),
    });
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8 w-full">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("form.mitigations.risk")} #{props.idx + 1}{" "}
              {risk.readable_name ? `- ${risk.readable_name}` : ""}
            </h3>
            <div className="mt-1 text-sm text-gray-500">
              <div>
                <span>
                  <b>{t("form.mitigations.description")}:</b> {risk.description}
                </span>
              </div>
              <div>
                <span>
                  <b>{t("form.mitigations.risk_type")}:</b>{" "}
                  {t("form.risk.options.group." + risk.group_id)}
                </span>
              </div>
              {isFeatureEnabled(Feature.RiskFullyMigated) && (
                <div>
                  <span className="flex items-center">
                    <b>Risiko hinreichend minimiert:</b>{" "}
                    {risk.fully_mitigated ? (
                      <CheckCircleIcon
                        className="ml-1 h-4 w-4 text-green-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <XCircleIcon
                        className="ml-1 h-4 w-4 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 space-x-1 self-start flex">
            <PrimaryButton
              onClick={() =>
                history.push(
                  `/projects/${projectId}/risks/${risk.resource_id}/edit`
                )
              }
            >
              {t("form.mitigations.edit")}
            </PrimaryButton>
            <DeleteButton onClick={handleDelete}>
              {t("form.mitigations.delete_risk")}
            </DeleteButton>
          </div>
        </div>
      </div>
      <div className="ml-4">
        <Collapsible
          useCustomStyles
          label={t("form.mitigations.risk_reducing_mitigations")}
          child={
            <div className="py-2 px-2">
              <div className="pb-3">
                <div>
                  <span className="text-md font-medium text-gray-500">
                    {t("form.mitigations.initial_risk")}:{" "}
                  </span>
                  <span>{totalRisk}</span>
                </div>
                <div>
                  {risk.mitigations?.length > 1 && (
                    <>
                      <span className="text-md font-medium text-gray-500">
                        {t("form.mitigations.risks_after_mitigations")}:
                      </span>
                      <span>
                        {calculateScoreAfterMitigation(
                          risk,
                          risk.mitigations.length - 1
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {(risk.mitigations?.length &&
                risk.mitigations.map((mitigation, idx) => {
                  return (
                    <div key={idx} className="pb-6">
                      <h3 className="text-md font-bold">
                        {t("form.mitigations.mitigation")} #{idx + 1}
                      </h3>
                      <dl
                        key={mitigation.id}
                        className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3"
                      >
                        <div>
                          <div className="sm:col-span-1">
                            <dt className="text-md font-medium text-gray-500">
                              {t("form.mitigations.type")}
                            </dt>
                            <dd className="mb-1 text-sm text-gray-900">
                              {mitigation.type}{" "}
                              {mitigation.type === "ESS" && (
                                <i>
                                  (
                                  {t(
                                    "form.mitigations.performance_level_required"
                                  )}{" "}
                                  {calculatePerformanceLevel(risk)})
                                </i>
                              )}
                              {mitigation.type === "PIK" && (
                                <>
                                  {mitigation.pictogram && (
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        {t("form.mitigations.pictogram")}
                                      </dt>
                                      <dd className="mb-1 text-sm text-gray-900">
                                        <img
                                          src={
                                            process.env.REACT_APP_DATA_URL +
                                            "/pictograms/svgs/" +
                                            mitigation.pictogram.match(
                                              /^[A-Z]\d{3}/
                                            )?.[0]
                                          }
                                          className="h-8 inline ml-2"
                                          alt=""
                                        />
                                      </dd>
                                    </div>
                                  )}
                                </>
                              )}
                            </dd>
                          </div>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-md font-medium text-gray-500">
                            {t("form.mitigations.description")}
                          </dt>
                          <dd className="mb-1 text-sm text-gray-900">
                            {mitigation.description}
                          </dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-md font-medium text-gray-500">
                            {t("form.mitigations.risks_after_mitigations")}
                          </dt>
                          <dd className="mb-1 text-sm text-gray-900">
                            <span className="font-bold">Score:</span>{" "}
                            <span>
                              {calculateScoreAfterMitigation(risk, idx)}
                            </span>
                            <br />
                            <span className="font-bold">Restrisiko: </span>
                            <span>{mitigation.left_risk}</span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  );
                })) || (
                <p className="mt-1 text-sm text-gray-900">
                  {t("form.mitigations.none")}
                </p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};
