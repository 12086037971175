import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

import Form from "forms/Form";

import { Risk } from "./types";
import { Modal } from "components/Modal/Modal";
import { useUpdateRisk } from "./hooks/useUpdateRisk";
import { Feature, useFeatureFlags } from "context/FeatureFlag";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useRiskGroups } from "pages/Risk/hooks/useRiskGroups";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { FormCb, buildRiskFormData, handleRiskGroupChange } from "./formdata";
import { ImageType } from "components/Documents/types";

interface RiskEditProps {
  projectId: string;
  risk: Risk;
}

export const RiskEdit: React.FunctionComponent<RiskEditProps> = ({
  projectId,
  risk,
}) => {
  const { t } = useTranslation();
  const { riskGroups } = useRiskGroups();
  const { isFeatureEnabled } = useFeatureFlags();
  const { project } = useProjectDetails(projectId);
  const mutate = useUpdateRisk(projectId, risk, project);
  const [formData, setFormData] = React.useState<any>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalPayload, setModalPayload] = React.useState<FormCb>();

  const handleSubmit = (formValues: FormCb) => {
    if (isFeatureEnabled(Feature.RiskFullyMigated)) {
      setModalVisible(true);
      setModalPayload(formValues);

      return;
    }

    return mutate(formValues);
  };

  React.useEffect(() => {
    if (!riskGroups || !risk) {
      return;
    }

    const formdata = buildRiskFormData(
      riskGroups,
      (key: string) => t(key),
      risk.group_id
    );

    formdata.sections[1].count = risk.mitigations?.length || 1;
    setFormData(formdata);
  }, [riskGroups, t, risk]);

  if (!formData) {
    return null;
  }

  const handleChange = (form: any) => {
    const selectedRiskGroup = riskGroups?.find(
      (rg) => rg.riskGroup.name === form.risk.group_id
    )!;

    const newForm = handleRiskGroupChange(selectedRiskGroup, (key: string) =>
      t(key)
    );
    setFormData(newForm);
  };

  const riskImg = project?.documents?.find(
    (doc) =>
      doc.type === ImageType.riskImage && doc.name.includes(risk.resource_id)
  );

  const _uploads = {
    [ImageType.riskImage]: riskImg
      ? {
          image: new File([], riskImg.name.split("/upload/")[1]),
          location: riskImg.location,
        }
      : undefined,
  };

  const { mitigations, ...restrisk } = risk;

  return (
    <div>
      <Form
        formData={formData}
        onChange={handleChange}
        saveText={t("risks.save")}
        defaultValues={{ mitigations, risk: restrisk, _uploads }}
        onStep={(values: any, stepID, setFieldValue) => {
          if (
            stepID === 0 &&
            values.mitigations?.length &&
            !values.mitigations[0].impact &&
            !values.mitigations[0].duration &&
            !values.mitigations[0].probability &&
            !values.mitigations[0].people_count &&
            !values.mitigations[0].detectability
          ) {
            setFieldValue("mitigations[0].impact", values.risk.impact);
            setFieldValue("mitigations[0].duration", values.risk.duration);
            setFieldValue(
              "mitigations[0].probability",
              values.risk.probability
            );
            setFieldValue(
              "mitigations[0].people_count",
              values.risk.people_count
            );
            setFieldValue(
              "mitigations[0].detectability",
              values.risk.detectability
            );
          }
        }}
        onAdd={(values: any) => {
          const lastMitigation = values.mitigations?.length
            ? values.mitigations[values.mitigations.length - 1]
            : values.risk;

          return {
            impact: lastMitigation.impact,
            duration: lastMitigation.duration,
            probability: lastMitigation.probability,
            people_count: lastMitigation.people_count,
            detectability: lastMitigation.detectability,
          };
        }}
        onSubmit={async (v) => handleSubmit(v as FormCb)}
      />
      <Modal
        onClose={() => setModalVisible(false)}
        show={modalVisible}
        width="1/2"
        content={
          <ConfirmMitigationStatus
            saveHandler={mutate}
            formData={modalPayload!}
          />
        }
      />
    </div>
  );
};

interface ConfirmMitigationStatusProps {
  saveHandler: (form: FormCb) => void;
  formData: FormCb;
}

const ConfirmMitigationStatus = (props: ConfirmMitigationStatusProps) => {
  const { t } = useTranslation();
  const [fullyMitigated, setFullyMitigated] = React.useState(
    props.formData.risk.fully_mitigated
  );

  const handleModalConfirmation = () => {
    const formWithMitigationStatus: FormCb = {
      ...props.formData,
      risk: {
        ...props.formData.risk,
        fully_mitigated: fullyMitigated,
      },
    };

    props.saveHandler(formWithMitigationStatus);
  };

  return (
    <div>
      <span className="text-xl">{t("risks.fully_mitigated.headline")}</span>
      <div className="pt-4">
        <div className="text-sm pb-4">
          <Trans i18nKey="risks.fully_mitigated.explanation">
            {/* NOTE: don't change the markup here. It would mess with the Translation */}
            <div></div>
            <div className="font-bold py-2"></div>
          </Trans>
          <Trans i18nKey="risks.fully_mitigated.wiki_pointer">
            <a
              href=""
              className="text-cyan-600 cursor-pointer hover:text-cyan-800"
            ></a>
          </Trans>
        </div>
        <div className="flex items-center h-5">
          <input
            required
            id="terms"
            type="checkbox"
            aria-describedby="terms"
            checked={fullyMitigated}
            onChange={(e) => setFullyMitigated(e.target.checked)}
            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-cyan-600 text-cyan-600"
          />
          <div className="ml-3 text-sm">
            <label htmlFor="terms">
              {t("risks.fully_mitigated.checkbox_label")}
            </label>
          </div>
        </div>

        <div className="pt-4">
          <PrimaryButton float="right" onClick={handleModalConfirmation}>
            {t("risks.save")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
