import _axios from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";

import { classNames } from "utils/classNames";
import { SelectOption } from "components/inputs/types";
import { styles } from "components/inputs/MultiSelectInput";

export interface PiktogramSelectProps {
  id: string;
  info?: string;
  label: string;
  value?: any;
  defaultValue?: any;
  required?: boolean;
  onBlur?: (e: any) => void;
}

export const Piktograms = (props: PiktogramSelectProps) => {
  const { t } = useTranslation();

  const { data: piktos, isLoading } = useQuery(["piktograms"], async () => {
    const d = await _axios.get(
      process.env.REACT_APP_DATA_URL + "/pictograms/manifest"
    );

    return d?.data && Object.keys(d.data);
  });

  const defaultVal = useMemo(() => {
    if (
      (typeof props.defaultValue === "string" ||
        props.defaultValue instanceof String) &&
      props.defaultValue !== ""
    ) {
      const pictos = (props.defaultValue as string)
        ?.split(";")
        .map((value: string) => ({
          value,
          label:
            piktos?.find((o: SelectOption) => o.value === value)?.label ||
            value,
        }));

      return pictos;
    }

    return props.defaultValue;
  }, [props.defaultValue, piktos]);

  if (isLoading) return null;

  return (
    <div className="relative inline-block text-left col-span-6">
      {t(props.label)}
      {!props.required && (
        <span id="phone-optional" className="text-sm text-gray-400 float-right">
          optional
        </span>
      )}

      <div>
        <Select
          isClearable
          isMulti
          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 sm:text-sm"
          styles={styles}
          classNames={{
            control: ({ isFocused }) =>
              `border border-gray-300 rounded-md ${
                isFocused ? "border-cyan-500" : ""
              }`,
            option: ({ isFocused, isSelected }) =>
              classNames(
                isFocused ? "bg-cyan-500" : "",
                isSelected ? "bg-cyan-400" : ""
              ),
          }}
          defaultValue={
            typeof defaultVal !== "string" &&
            defaultVal?.map((v: SelectOption) => {
              const pictoId = (v.value as string).match(/^[A-Z]\d{3}/)?.[0];

              return {
                value: `${pictoId}: ${t(`pictograms.${pictoId}`)}`,
                label: pictoId ? (
                  <>
                    <img
                      src={
                        process.env.REACT_APP_DATA_URL +
                        "/pictograms/svgs/" +
                        pictoId
                      }
                      className="h-6 inline mr-2"
                      alt=""
                    />
                    {pictoId}: {t(`pictograms.${pictoId}`)}
                  </>
                ) : (
                  t("form.basic.no_pictogram")
                ),
              };
            })
          }
          onChange={(value) => {
            const uniqueVals = value.filter(onlyUnique);

            props.onBlur &&
              props.onBlur({
                target: {
                  value: uniqueVals
                    .map((v) => v.value.match(/^[A-Z]\d{3}/)[0])
                    .join(";"),
                },
              });
          }}
          options={piktos?.map((pictoName: string) => ({
            value: `${pictoName}: ${t(`pictograms.${pictoName}`)}`,
            label: (
              <>
                <img
                  src={
                    process.env.REACT_APP_DATA_URL +
                    "/pictograms/svgs/" +
                    pictoName
                  }
                  className="h-6 inline mr-2"
                  alt=""
                />
                {pictoName}: {t(`pictograms.${pictoName}`)}
              </>
            ),
          }))}
        />
      </div>
    </div>
  );
};

function onlyUnique(value: string, index: number, array: MultiValue<string>) {
  return array.indexOf(value) === index;
}
