import {
  FormSection,
  FormFieldType,
} from "@slootsantos/certain-forms/dist/types";

import { Risk, RiskMitigation } from "./types";
import { RiskGroup } from "pages/Risk/hooks/useRiskGroups";
import { TFunction } from "i18next";
import { RemainingRiskScoreFormField } from "./RemainingRiskScoreFormField";
import { PerformanceLevelScoreFormField } from "./PerformanceLevelScore";
import { ImageUploads } from "pages/Project/hooks/useCreateProject";
import { ImageType } from "components/Documents/types";

type FormMitigation = { applied_standards: { name: string }[] } & Omit<
  RiskMitigation,
  "applied_standards"
>;
export interface FormCb {
  risk: Risk;
  mitigations: FormMitigation[];
  _uploads: ImageUploads;
}

export interface Ex {
  sections: FormSection[];
}

export const buildRiskFormData = (
  riskGroups: RiskGroup[],
  translator: TFunction,
  selectedGroup: string
) => {
  const newForm = { ...formData };
  newForm.sections[0].fields[1].options = riskGroups.map((riskGroup, idx) => ({
    value: riskGroup.riskGroup.name,
    label: translator("form.risk.options.group." + riskGroup.riskGroup.name),
  }));

  // phase options
  newForm.sections[0].fields[4].options =
    newForm.sections[0].fields[4].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.phase." + option.value),
    }));

  // impact options
  newForm.sections[0].fields[9].options =
    newForm.sections[0].fields[9].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.impact." + option.value),
    }));

  // people_count options
  newForm.sections[0].fields[10].options =
    newForm.sections[0].fields[10].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.people_count." + option.value),
    }));

  // duration options
  newForm.sections[0].fields[11].options =
    newForm.sections[0].fields[11].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.duration." + option.value),
    }));

  // detectability options
  newForm.sections[0].fields[12].options =
    newForm.sections[0].fields[12].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.detectability." + option.value),
    }));

  // probability options
  newForm.sections[0].fields[13].options =
    newForm.sections[0].fields[13].options?.map((option, idx) => ({
      value: option.value,
      label: translator("form.risk.options.probability." + option.value),
    }));

  const builtForm = handleRiskGroupChange(
    riskGroups.find((rg) => rg.riskGroup.name === selectedGroup)!,
    translator,
    newForm
  );

  return builtForm;
};

export const handleRiskGroupChange = (
  selectedRiskGroup: RiskGroup,
  translator: TFunction,
  _formData?: any
) => {
  const newForm = { ...(_formData || formData) };
  newForm.sections[0].fields[3].options = selectedRiskGroup?.consequences.map(
    (consequence) => ({
      value: consequence.name,
      label: translator("form.risk.options.consequence." + consequence.name),
    })
  );

  newForm.sections[0].fields[2].options = selectedRiskGroup?.origins.map(
    (origin) => ({
      value: origin.name,
      label: translator("form.risk.options.origin." + origin.name),
    })
  );

  return newForm;
};

export const formData: Ex = {
  sections: [
    {
      label: "form.risk.label",
      name: "risk",
      subline: "form.risk.subline",
      fields: [
        {
          name: "readable_name",
          label: "form.risk.readable_name",
          type: FormFieldType.full,
          required: false,
        },
        {
          name: "group_id",
          label: "form.risk.group",
          type: FormFieldType.select,
          options: [{ value: "mechanical", label: "Mechanische Gefährdung" }],
          required: true,
        },
        {
          name: "origin_id",
          label: "form.risk.origin",
          type: FormFieldType.select,
          options: [],
          required: true,
        },
        {
          name: "consequence_id",
          label: "form.risk.consequence",
          info: "form.risk.phase_info",
          type: FormFieldType.multiSelect,
          options: [],
          required: true,
        },
        {
          name: "phase_id",
          label: "form.risk.phase",
          type: FormFieldType.multiSelect,
          info: "form.risk.phase_info",
          // TODO: use same magic as for risk-group consequence & origins
          options: [
            { value: "transport", label: "Transport" },
            {
              value: "installation",
              label: "Montage und Installation",
            },
            {
              value: "commissioning",
              label: "Inbetriebnahme",
            },
            {
              value: "setup",
              label: "Einrichten (Anlernen/Programmieren)",
            },
            {
              value: "adapt",
              label: "Rüsten/Umrüsten",
            },
            { value: "operations", label: "Betrieb" },
            { value: "disturbance", label: "Störung" },
            { value: "debugging", label: "Fehlersuche und Beseitigung" },
            { value: "maintenance", label: "Instandhaltung" },
            { value: "cleaning", label: "Reinigung" },
            { value: "decommissioning", label: "Außerbetriebnahme" },
            { value: "deinstallation", label: "Demontage" },
            { value: "disposal", label: "Entsorgung" },
          ],
          required: true,
        },
        {
          name: "description",
          label: "form.risk.description",
          type: FormFieldType.area,
          required: true,
        },
        {
          name: ImageType.riskImage,
          label: "form.risk.image",
          type: FormFieldType.upload,
        },
        {
          name: "protection_goal",
          label: "form.risk.protection_goal",
          type: FormFieldType.long,
          required: true,
        },
        {
          name: "personal",
          label: "form.risk.personal",
          type: FormFieldType.long,
          required: true,
        },
        {
          name: "impact",
          label: "form.risk.impact",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Geringfügig" },
            { value: 2, label: "(2) Mittelmäßig" },
            { value: 3, label: "(3) Schwerwiegend" },
            { value: 4, label: "(4) Katastrophal" },
          ],
          required: true,
        },
        {
          name: "people_count",
          label: "form.risk.people_count",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Eine Person" },
            { value: 2, label: "(2) Mehrere Personen" },
            { value: 3, label: "(3) Öffentlichkeit" },
          ],
          required: true,
        },
        {
          name: "duration",
          label: "form.risk.duration",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Seltener als einmal jährlich" },
            { value: 2, label: "(2) Jährlich bis 14-täglich" },
            { value: 3, label: "(3) 14-täglich bis täglich" },
            { value: 4, label: "(4) Täglich bis mehrmals die Stunde" },
          ],
          required: true,
        },
        {
          name: "detectability",
          label: "form.risk.detectability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Wahrscheinlich" },
            { value: 2, label: "(2) Möglich" },
            { value: 3, label: "(3) Unwahrscheinlich" },
          ],
          required: true,
        },
        {
          name: "probability",
          label: "form.risk.probability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Vernachlässigbar" },
            { value: 2, label: "(2) Gering" },
            { value: 3, label: "(3) Möglich" },
            { value: 4, label: "(4) Groß" },
            { value: 5, label: "(5) Sehr groß" },
          ],
          required: true,
        },
        {
          name: "cxccc",
          label: "ccccc",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <RemainingRiskScoreFormField isRisk {...props} />
          ),
        },
      ],
    },
    {
      label: "form.mitigations.label",
      name: "mitigations",
      subline: "form.mitigations.subline",
      isList: true,
      count: 1,
      fields: [
        {
          name: "type",
          label: "form.mitigations.type",
          type: FormFieldType.select,
          options: [
            { value: "INH", label: "form.mitigations.options.type.INH" },
            { value: "EMS", label: "form.mitigations.options.type.EMS" },
            { value: "ESS", label: "form.mitigations.options.type.ESS" },
            { value: "PSA", label: "form.mitigations.options.type.PSA" },
            { value: "PIK", label: "form.mitigations.options.type.PIK" },
            { value: "BDA", label: "form.mitigations.options.type.BDA" },
          ],
          required: true,
        },
        {
          name: "cxccc",
          label: "form.mitigations.ccccc",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <PerformanceLevelScoreFormField {...props} />
          ),
        },
        {
          name: "description",
          label: "form.mitigations.description",
          type: FormFieldType.area,
          required: true,
        },
        {
          name: "pictogram",
          label: "form.mitigations.pictogram",
          type: FormFieldType.picto,
        },
        {
          name: "impact",
          label: "form.mitigations.impact",
          type: FormFieldType.select,
          required: true,
          transform: "int",
          options: [
            { value: 1, label: "form.mitigations.options.impact.1" },
            { value: 2, label: "form.mitigations.options.impact.2" },
            { value: 3, label: "form.mitigations.options.impact.3" },
            { value: 4, label: "form.mitigations.options.impact.4" },
          ],
        },
        {
          name: "people_count",
          label: "form.mitigations.people_count",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "form.mitigations.options.people_count.1" },
            { value: 2, label: "form.mitigations.options.people_count.2" },
            { value: 3, label: "form.mitigations.options.people_count.3" },
          ],
          required: true,
        },
        {
          name: "duration",
          label: "form.mitigations.duration",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "form.mitigations.options.duration.1" },
            { value: 2, label: "form.mitigations.options.duration.2" },
            { value: 3, label: "form.mitigations.options.duration.3" },
            { value: 4, label: "form.mitigations.options.duration.4" },
          ],
          required: true,
        },
        {
          name: "detectability",
          label: "form.mitigations.detectability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "form.mitigations.options.detectability.1" },
            { value: 2, label: "form.mitigations.options.detectability.2" },
            { value: 3, label: "form.mitigations.options.detectability.3" },
          ],
          required: true,
        },
        {
          name: "probability",
          label: "form.mitigations.probability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "form.mitigations.options.probability.1" },
            { value: 2, label: "form.mitigations.options.probability.2" },
            { value: 3, label: "form.mitigations.options.probability.3" },
            { value: 4, label: "form.mitigations.options.probability.4" },
            { value: 5, label: "form.mitigations.options.probability.5" },
          ],
          required: true,
        },
        {
          name: "left_risk",
          label: "form.mitigations.left_risk",
          type: FormFieldType.area,
          required: true,
        },
        {
          name: "applied_standards",
          label: "form.mitigations.applied_standards",
          type: FormFieldType.standardsSelect,
          required: true,
        },
        {
          name: "any",
          label: "form.mitigations.any",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <RemainingRiskScoreFormField {...props} />
          ),
        },
      ],
    },
  ],
};

export const defaultVal = {
  risk: {
    assessment: {
      impact: 1,
      people_count: 1,
      probability: 1,
      detectability: 1,
      duration: 1,
    },
  },
  mitigations: {
    type: "PSA",
    impact: 1,
    people_count: 1,
    probability: 1,
    detectability: 1,
    duration: 1,
    applied_standards: [],
  },
};
