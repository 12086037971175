import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { classNames } from "utils/classNames";
import { Steps } from "types";
import NewBadge from "components/common/NewBadge";
import { Feature, useFeatureFlags } from "context/FeatureFlag";

interface ProgressStep {
  name: string;
  label: string;
  stepKey: Steps;
  complete: boolean;
  location: string;
  subline: string;
  isPreview?: (checker: (feat: Feature) => boolean) => boolean;
  isSubStep?: boolean;
  possibleSubsteps?: Steps[];
  mappedSteps?: ProgressStep[];
  hasUpdates?: boolean;
}
interface ProgressProps {
  steps: ProgressStep[];
}

const Checkmark = ({ className = "h-5 w-5" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="white"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

export const Progress = (props: ProgressProps) => {
  const { t } = useTranslation();
  const nestedSteps = nestSubsteps(props.steps);

  return (
    <nav aria-label="Progress" className="mt-2 py-4 px-4">
      <span className="text-lg font-medium">{t("steps.progress")}</span>
      <div className="pt-6">
        <ol className="overflow-hidden">
          {nestedSteps?.map((step, stepIdx) => (
            <li
              data-test={`progress-step-${step.name}`}
              key={step.name}
              className={classNames(
                stepIdx !== props.steps.length - 1 ? "pb-10" : "",
                "relative"
              )}
            >
              {step.complete ? (
                <CompleteStep
                  {...step}
                  isLast={stepIdx === nestedSteps.length - 1}
                />
              ) : (
                <>
                  {stepIdx !== nestedSteps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-cyan-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <Link
                    to={step.location}
                    className="relative flex items-start group"
                  >
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        {step.label}
                      </span>
                      <span className="text-sm text-gray-500">
                        {step.subline}
                      </span>
                    </span>
                  </Link>

                  {step.mappedSteps?.map((substep, substepIdx) => (
                    <SubStep
                      {...substep}
                      key={substep.stepKey}
                      isLast={substepIdx === step.mappedSteps!.length - 1}
                      isComplete={substep.complete}
                    />
                  ))}
                </>
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

const SubStep = (
  props: ProgressStep & { isLast: boolean; isComplete: boolean }
) => {
  if (!props.isComplete) {
    return (
      <div className="relative left-10">
        <Link
          to={props.location}
          className="relative flex items-start group top-4"
        >
          <div className="relative flex items-center group">
            <span className="h-9 flex items-center" aria-hidden="true">
              <span className="ml-1 relative z-10 w-4 h-4 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
              </span>
            </span>
            <span className="ml-2 min-w-0 flex flex-col group relative">
              <span className="text-sm text-gray-500">{props.label}</span>
            </span>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="relative left-10">
      <Link
        to={props.location}
        className="relative flex items-start group top-4"
      >
        <div className="relative flex items-center group">
          <span className="h-9 flex items-center">
            <span className="ml-1 relative z-10 w-4 h-4 flex items-center justify-center bg-cyan-600 rounded-full group-hover:bg-cyan-800">
              <Checkmark className="h-3 w-3" />
            </span>
          </span>
          <span className="ml-2 min-w-0 flex flex-col group relative">
            <span className="text-sm text-gray-500">{props.label}</span>
          </span>
        </div>
      </Link>
    </div>
  );
};

const CompleteStep = (props: ProgressStep & { isLast: boolean }) => {
  const { isFeatureEnabled } = useFeatureFlags();

  if (props.isPreview?.(() => isFeatureEnabled(Feature.InstructionManual))) {
    return (
      <>
        {!props.isLast && (
          <div
            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-cyan-600"
            aria-hidden="true"
          />
        )}
        <div className="relative flex items-start group">
          <span className="h-9 flex items-center">
            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full">
              <Checkmark />
            </span>
          </span>
          <span className="ml-4 min-w-0 flex flex-col group relative cursor-default">
            <span className="text-xs font-semibold tracking-wide uppercase text-gray-300">
              {props.label}
            </span>
            <span className="text-sm text-gray-300">{props.subline}</span>

            <span className="absolute opacity-0 rounded bg-cyan-600 p-2 text-xs text-white group-hover:opacity-100">
              ✨ Coming soon!
            </span>
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      {!props.isLast ? (
        <div
          className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-cyan-600"
          aria-hidden="true"
        />
      ) : null}
      <Link to={props.location} className="relative flex items-start group">
        <span className="h-9 flex items-center">
          <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-cyan-600 rounded-full group-hover:bg-cyan-800">
            <Checkmark />
          </span>
        </span>
        <span className="ml-4 min-w-0 flex flex-col">
          <span className="text-xs font-semibold ">
            <span className="tracking-wide uppercase">{props.label}</span>
            {/* {props.hasUpdates && (
              <div className="inline ">
                <NewBadge tKey={`news.steps.${props.stepKey}`} />
              </div>
            )} */}
          </span>
          <span className="text-sm text-gray-500">{props.subline}</span>
        </span>
      </Link>
      {props.mappedSteps?.map((substep, substepIdx) => (
        <SubStep
          {...substep}
          key={substep.stepKey}
          isLast={substepIdx === props.mappedSteps!.length - 1}
          isComplete={substep.complete}
        />
      ))}
    </>
  );
};

function nestSubsteps(steps: ProgressStep[]): ProgressStep[] {
  const stepMap = new Map<Steps, ProgressStep>();
  const result: ProgressStep[] = [];

  // Create a map for easy lookup of steps by their key
  for (const step of steps) {
    stepMap.set(step.stepKey, step);
  }

  // Iterate through the steps and nest the substeps
  for (const step of steps) {
    if (step.possibleSubsteps) {
      step.mappedSteps = step.possibleSubsteps
        .map((substepKey) => {
          const substep = stepMap.get(substepKey);
          if (substep) {
            stepMap.delete(substep.stepKey); // Remove substeps from the top level
          }
          return substep;
        })
        .filter(Boolean) as ProgressStep[];
    }
  }

  // Create the resulting array without the nested substeps at the top level
  for (const step of steps) {
    if (stepMap.has(step.stepKey)) {
      result.push(step);
    }
  }

  return result;
}
