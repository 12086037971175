import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import _axios, { AxiosInstance, AxiosResponse } from "axios";

import { Project } from "types";
import { useClient } from "utils/client";
import { Category } from "components/common/ProductCategorySelector";

export interface UploadableImage {
  image: File;
  key: string;
  touched?: boolean;
}

export type ImageUploads = Record<string, UploadableImage>;
interface ProjectCreateAPIResponse {
  resourceId: string;
}

export interface ProjectPayload {
  project: Project;
  uploads: ImageUploads;
}

export const uploadSignedS3 = async (
  client: AxiosInstance,
  uploadPrefix: string,
  file: File
) => {
  const { data } = await client.post(`/documents/signing/${uploadPrefix}`);

  const { data: s3Resp } = await _axios.put(data, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  return s3Resp;
};

export const requestUpload = async (
  client: AxiosInstance,
  file: File,
  type: string,
  resourceId?: string
) => {
  const uploadName = `/upload/${file.name}`;
  const filePrefix = `${resourceId}${uploadName}`;

  await uploadSignedS3(client, filePrefix, file);

  const { data: docData } = await client.post<
    any,
    AxiosResponse<{
      id: number;
      name: string;
      project_id: number;
      type: string;
    }>
  >(`/projects/${resourceId}/documents`, {
    type: type,
    name: uploadName,
  });

  return docData;
};

export const requestDelete = async (
  client: AxiosInstance,
  type: string,
  resourceId?: string
) => {
  await client.delete(`/projects/${resourceId}/documents/${type}`);
};

export const useCreateProject = () => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (data: Partial<ProjectPayload>) => {
      const { data: response } = await client.post<ProjectCreateAPIResponse>(
        "/projects",
        {
          payload: {
            ...data.project,
            projectdata: {
              ...data.project!.projectdata,
              category: data.project?.projectdata?.category || Category.none,
            },
          },
        }
      );

      if (data?.uploads) {
        for (const fileKey in data.uploads) {
          const file = data.uploads[fileKey];
          if (!file) continue;

          await requestUpload(
            client,
            data.uploads[fileKey].image,
            fileKey,
            response.resourceId
          );
        }
      }

      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("projects");
        history.push(`/projects/${data.resourceId}`);
      },
    }
  );

  return { createProject: mutate, isLoading };
};
