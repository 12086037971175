import {
  DocumentData,
  Engineer,
  Manufacturer,
  Project,
  ProjectData,
  Steps,
} from "types";
import { Feature } from "context/FeatureFlag";
import { Category } from "components/common/ProductCategorySelector";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { requiredDocsMapping } from "components/Documents/data/requiredDocsMapping";

export interface PossibleStep {
  name: string;
  label: string;
  stepKey: Steps;
  subline: string;
  isSubStep?: boolean;
  isPreview?: (hander: (feat: Feature) => boolean) => boolean;
  completeFn: (p: Project) => { complete: boolean; reason?: string };
  location: (id: string, p: Project) => string;
  possibleSubsteps?: Steps[];
  hasUpdates?: boolean;
}

interface StepChecker {
  checker: (p: Project) => boolean;
  reason?: string;
}

const manufacturerIsComplete = (manufacturer: Manufacturer) => {
  return (
    manufacturer.company &&
    manufacturer.name &&
    manufacturer.street_address &&
    manufacturer.post_code &&
    manufacturer.city &&
    manufacturer.country &&
    manufacturer.email_adress &&
    manufacturer.phone
  );
};

const engineerIsComplete = (engineer: Engineer) => {
  return (
    engineer.company &&
    engineer.name &&
    engineer.street_address &&
    engineer.post_code &&
    engineer.city &&
    engineer.country &&
    engineer.email_adress &&
    engineer.phone
  );
};

const documentsExist = (
  projectdata: ProjectData,
  documents: DocumentData[]
) => {
  const requiredDocs = requiredDocsMapping[projectdata.category];
  const containsAllDocs = requiredDocs.every((d) =>
    documents?.find((doc) => doc.type === d)
  );

  return containsAllDocs;
};

const condition: Record<Steps, StepChecker[]> = {
  [Steps.basedata]: [
    {
      checker: ({ projectdata }) => !!projectdata.category,
      reason: "Das Produkt hat noch keine Kategorie.",
    },
    {
      checker: ({ projectdata }) => !!projectdata.product_name,
      reason: "Das Produkt hat keinen Produktnamen.",
    },
    {
      checker: ({ manufacturer }) => !!manufacturerIsComplete(manufacturer),
      reason: "Der Hersteller des Produkts ist unvollständig.",
    },
    {
      checker: ({ engineer }) => !!engineerIsComplete(engineer),
      reason:
        "Der Dokumentationsbevollmächtigte des Produkts ist unvollständig.",
    },
  ],
  [Steps.boundary]: [
    {
      checker: ({ boundary }) => !!boundary.expected_usage,
      reason:
        "Die Bestimmungsgemäße Verwendung des Produkts ist noch nicht festgelegt worden.",
    },
  ],
  [Steps.manual]: [
    {
      checker: ({ manualExist }) => manualExist,
      reason: "Es wurde noch keine Betriebsanleitung erstellt.",
    },
  ],
  [Steps.standards]: [
    {
      checker: ({ projectdata }) => !!projectdata.completed_research,
      reason:
        "Die relevanten Normen für das Produkt wurden noch nicht recherchiert.",
    },
  ],
  [Steps.risks]: [
    {
      checker: ({ risksExist, risks }) => risksExist || risks?.length > 0,
      reason: "Es wurden noch keine Gefährdungen für das Produkt angelegt.",
    },
  ],
  [Steps.documents]: [
    {
      checker: ({ projectdata, documents }) =>
        documentsExist(projectdata, documents),
      reason: "Es wurden noch keine abschließenden Dokumente erzeugt.",
    },
  ],
  // Questionaire Conditions
  [Steps.questionaire]: [
    {
      checker: ({ projectdata, standardsExist }) =>
        projectdata.completed_toplevel_research ||
        standardsExist ||
        projectdata.category === Category.exit,
      reason:
        "Die Richtlinienrecherche wurde noch nicht bis zum Ende durchgeführt.",
    },
  ],
  [Steps.questionaireMrl]: [
    {
      checker: ({ directives }) =>
        !!directives.find(
          (d) => d.name === Directive.mrl && d.completed_research
        ),
      reason:
        "Die Richtlinienrecherche wurde noch nicht bis zum Ende durchgeführt.",
    },
  ],
  [Steps.questionaireMvo]: [
    {
      checker: ({ projectdata, standardsExist }) =>
        standardsExist || projectdata.category === Category.exit,
      reason:
        "Die Richtlinienrecherche wurde noch nicht bis zum Ende durchgeführt.",
    },
  ],
  [Steps.questionaireLvd]: [
    {
      checker: ({ directives }) =>
        !!directives.find(
          (d) => d.name === Directive.lvd && d.completed_research
        ),
      reason:
        "Die Richtlinienrecherche wurde noch nicht bis zum Ende durchgeführt.",
    },
  ],
  [Steps.questionaireEmv]: [
    {
      checker: ({ directives }) =>
        !!directives.find(
          (d) => d.name === Directive.emv && d.completed_research
        ),
      reason:
        "Die Richtlinienrecherche wurde noch nicht bis zum Ende durchgeführt.",
    },
  ],
};

const completeFnRunner = (conditions: StepChecker[], project: Project) => {
  const allChecks = conditions.map(({ checker, reason }) => {
    if (!checker(project)) {
      return { failed: true, reason };
    }

    return { failed: false };
  });

  const isComplete = !allChecks.some((c) => c.failed);
  const failedReasons = allChecks
    .filter((c) => c.failed)
    ?.map((c) => c.reason)
    ?.join(", ");

  return { complete: isComplete, reason: failedReasons };
};

export const possibleSteps: Record<Steps, PossibleStep> = {
  [Steps.basedata]: {
    stepKey: Steps.basedata,
    name: "steps.basedata.label",
    label: "steps.basedata.label",
    subline: "steps.basedata.subline",
    location: (id) => `/projects/${id}/edit`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.basedata], project),
  },
  [Steps.boundary]: {
    stepKey: Steps.boundary,
    name: "steps.boundary.label",
    label: "steps.boundary.label",
    subline: "steps.boundary.subline",
    location: (id) => `/projects/${id}/edit/boundaries`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.boundary], project),
  },
  [Steps.manual]: {
    stepKey: Steps.manual,
    name: "steps.manual.label",
    label: "steps.manual.label",
    subline: "steps.manual.subline",
    location: (id) => `/projects/${id}/manual`,
    completeFn: (project) => completeFnRunner(condition[Steps.manual], project),
    isPreview: (isFeatureEnabled) =>
      !isFeatureEnabled(Feature.InstructionManual),
  },
  [Steps.standards]: {
    stepKey: Steps.standards,
    name: "steps.standards.label",
    label: "steps.standards.label",
    subline: "steps.standards.subline",
    location: (id) => `/projects/${id}/edit/standards`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.standards], project),
  },
  [Steps.questionaire]: {
    stepKey: Steps.questionaire,
    name: "steps.questionaire.label",
    label: "steps.questionaire.label",
    subline: "steps.questionaire.subline",
    location: (id) => `/projects/${id}/questionaire`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.questionaire], project),
    possibleSubsteps: [
      Steps.questionaireMrl,
      Steps.questionaireMvo,
      Steps.questionaireLvd,
      Steps.questionaireEmv,
    ],
    hasUpdates: true,
  },
  [Steps.questionaireMrl]: {
    stepKey: Steps.questionaireMrl,
    name: "steps.questionaire.mrl.label",
    isSubStep: true,
    label: "steps.questionaire.mrl.label",
    subline: "steps.questionaire.mrl.subline",
    location: (id) => `/projects/${id}/questionaire/mrl`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.questionaireMrl], project),
  },
  [Steps.questionaireMvo]: {
    stepKey: Steps.questionaireMvo,
    name: "steps.questionaire.mvo.label",
    isSubStep: true,
    label: "steps.questionaire.mvo.label",
    subline: "steps.questionaire.mvo.subline",
    location: (id) => `/projects/${id}/questionaire/mvo`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.questionaireMvo], project),
  },
  [Steps.questionaireLvd]: {
    stepKey: Steps.questionaireLvd,
    name: "steps.questionaire.lvd.label",
    isSubStep: true,
    label: "steps.questionaire.lvd.label",
    subline: "steps.questionaire.lvd.subline",
    location: (id) => `/projects/${id}/questionaire/lvd`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.questionaireLvd], project),
  },
  [Steps.questionaireEmv]: {
    stepKey: Steps.questionaireEmv,
    name: "steps.questionaire.emv.label",
    isSubStep: true,
    label: "steps.questionaire.emv.label",
    subline: "steps.questionaire.emv.subline",
    location: (id) => `/projects/${id}/questionaire/emv`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.questionaireEmv], project),
  },
  [Steps.risks]: {
    stepKey: Steps.risks,
    name: "steps.risks.label",
    label: "steps.risks.label",
    subline: "steps.risks.subline",
    location: (id) => `/projects/${id}/risks`,
    completeFn: (project) => completeFnRunner(condition[Steps.risks], project),
  },
  [Steps.documents]: {
    stepKey: Steps.documents,
    name: "steps.documents.label",
    label: "steps.documents.label",
    subline: "steps.documents.subline",
    location: (id) => `/projects/${id}/documents`,
    completeFn: (project) =>
      completeFnRunner(condition[Steps.documents], project),
  },
};

const fullSteps = [
  Steps.basedata,
  Steps.boundary,
  Steps.questionaire,
  Steps.standards,
  Steps.risks,
  Steps.manual,
  Steps.documents,
];

export const fallbackRequiredSteps = {
  [Category.machine]: fullSteps,
  [Category.lvd]: fullSteps,
  [Category.sample]: [Steps.basedata, Steps.boundary, Steps.manual],
  [Category.none]: [Steps.basedata, Steps.boundary, Steps.questionaire],

  [Category.partially_completed_machinery]: fullSteps,
  [Category.assembly_of_machinery]: fullSteps,
  [Category.safety_Component]: fullSteps,
  [Category.lifting_accessory]: fullSteps,
  [Category.chains_ropes_webbing]: fullSteps,
  [Category.removable_mechanical_transmission_device]: fullSteps,
  [Category.lifting_device_human_power]: fullSteps,
  [Category.interchangeable_equipment]: fullSteps,
  [Category.annex_iv]: fullSteps,
  [Category.exit]: [
    Steps.basedata,
    Steps.boundary,
    Steps.questionaire,
    Steps.documents,
  ],
};

const directiveToStep: Record<Directive, Partial<Steps>> = {
  [Directive.mrl]: Steps.questionaireMrl,
  [Directive.mvo]: Steps.questionaireMvo,
  [Directive.lvd]: Steps.questionaireLvd,
  [Directive.pressurevessel]: Steps.questionaireLvd,
  [Directive.pressureequip]: Steps.questionaireLvd,
  [Directive.rohs]: Steps.questionaireLvd,
  [Directive.atex]: Steps.questionaireLvd,
  [Directive.outdoor]: Steps.questionaireLvd,
  [Directive.emv]: Steps.questionaireEmv,
  [Directive._none]: Steps.questionaireLvd,
};

export const getRequiredStepsFromDirective = (
  dirs: Directive[],
  category: Category
) => {
  const directiveSteps = dirs.map((d) => directiveToStep[d]);
  const allSteps = [...fallbackRequiredSteps[category]];

  const idx = allSteps.findIndex((s) => s === Steps.questionaire);
  allSteps.splice(idx + 1, 0, ...directiveSteps);

  return allSteps;
};
