import { useAccount } from "pages/Settings/hooks/useAccount";
import React, { PropsWithChildren, useContext } from "react";

export enum Feature {
  OnTheFlyTranslations,
  SecondBatchLanguages,
  MultiDirectiveSearch,
  EmvDirectiveSearch,
  InstructionManual,
  RiskFullyMigated,
  MultiLanguage,
  MultiUser,
}

interface IFeatureFlagContext {
  isFeatureEnabled: (feature: Feature, accountId?: string) => boolean;
}

export const FeatureFlagContext = React.createContext<
  Partial<IFeatureFlagContext>
>({});

const isDev = process.env.REACT_APP_APP_HOST !== "https://app.certain-cloud.de";
const isLocal = process.env.REACT_APP_APP_HOST === "http://localhost:3000";

const staticFeatureFlags: Record<Feature, boolean> = {
  [Feature.OnTheFlyTranslations]: isDev,
  [Feature.SecondBatchLanguages]: isDev,
  [Feature.MultiDirectiveSearch]: true,
  [Feature.EmvDirectiveSearch]: isDev,
  [Feature.InstructionManual]: isDev,
  [Feature.RiskFullyMigated]: true,
  [Feature.MultiLanguage]: true,
  [Feature.MultiUser]: true,
};

const accountFeatureFlags: Record<Feature, string[]> = {
  [Feature.OnTheFlyTranslations]: [],
  [Feature.SecondBatchLanguages]: [],
  [Feature.MultiDirectiveSearch]: [],
  [Feature.EmvDirectiveSearch]: [],
  [Feature.InstructionManual]: [
    "2iy81ZAzxrTgaFTNsouG33h3TOL", // al@arm*** @p
    "2hheNHpbgTb3UbqtqyBHFJuG1wK", // f.s@c*** @p
    "2YZmfcgHEwRqfhDhzBEfzRYsN8s", // b.f@c*** @p
    "2Y75iNh1rxmzHNXFVQe4HbDqU1u", // n.f@c*** @p
    "2hmUfgqIrcy3veoejK0kdS4rajy", // info@c*** @p
    "2q1CbYD1RyTOBx761vBHIMX0APK", // j.e@c*** @p
    "2d2MiFrppkg0Z2FzkHYOKmy6GFM", // d.d@she*** @p
    "2iKY8knAFNGLtMjelIOuhlTWWuV", // l.k@k*** @p
    "2pkPMTfw7xz0qNWwS3emd05p8Ek", // t.f@k*** @p
  ],
  [Feature.RiskFullyMigated]: [],
  [Feature.MultiLanguage]: [],
  [Feature.MultiUser]: [],
};

export const FeatureFlagProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const { account } = useAccount();
  const isFeatureEnabled = (feature: Feature, accountId?: string) => {
    if (accountId || account?.resource_id) {
      const accountIdToCheck = accountId || account!.resource_id;

      return (
        !!staticFeatureFlags[feature] ||
        accountFeatureFlags[feature].includes(accountIdToCheck)
      );
    }

    return !!staticFeatureFlags[feature];
  };

  return (
    <FeatureFlagContext.Provider value={{ isFeatureEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider"
    );
  }

  return context! as IFeatureFlagContext;
};
