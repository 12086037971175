export enum PDFStatus {
  ready = "ready",
  loading = "loading",
  pending = "pending",
  missingData = "missingData",
}

export enum DocType {
  risk = "risk",
  plate = "plate",
  manual = "manual",
  conformity = "conformity",
  exitConformity = "exitConformity",
  installationConformity = "installationConformity",
}

export enum ImageType {
  riskImage = "risk_image",
  manufacturerLogo = "manufacturer_logo",
  mainProductImage = "main_product_image",
}

export type UploadType = ImageType & DocType;
