import { Suspense } from "react";
import { TailSpin as Loader } from "react-loader-spinner";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import Routes from "./router";
import { AuthProvider } from "./context/Auth";
import { Footer } from "components/Footer/Footer";
import { FeedbackProvider } from "context/Feedback";
import { ControlProvider } from "./context/Controls";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import { TrackingProvider } from "context/Tracking";
import { ConfirmationProvider } from "context/Confirmation";
import { FeatureFlagProvider } from "context/FeatureFlag";

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [404];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (
          axios.isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 600000,
    },
  },
});

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader color="#0991b1" height={100} width={100} />
    </div>
  );
};

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <ControlProvider>
          <QueryClientProvider client={queryClient}>
            <FeatureFlagProvider>
              <AuthProvider>
                <TrackingProvider>
                  <FeedbackProvider>
                    <Suspense fallback={<Loading />}>
                      <ConfirmationProvider>
                        <Routes></Routes>
                        <Footer />
                      </ConfirmationProvider>
                    </Suspense>

                    <ReactQueryDevtools initialIsOpen={false} />
                  </FeedbackProvider>
                </TrackingProvider>
              </AuthProvider>
            </FeatureFlagProvider>
          </QueryClientProvider>
        </ControlProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
